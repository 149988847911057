<template>
  <a-modal
  width="70%"
  :visible="value"
  :title="title"
  okText="确定"
  cancelText="取消"
  @cancel="handleCancel"
  @ok="saveSubmit">
      <div class="flex">
        <span>学校：</span>
        <!-- <initSelect class="myselect" v-model:value="sid" :list="schoolList" placeholder="请选择学校" mode="multiple"></initSelect> -->
        <initSelect class="myselect" v-model:value="sid" :list="schoolList" placeholder="请选择学校"></initSelect>
      </div>
      <div class="head">已选列表</div>
      <div class="table-box">
        <a-table
        :columns="columns"
        :data-source="list"
        :pagination="false"
        class="table"
        rowKey="id"
        size="small"
        :scroll="{y: 400}">
          <template #action1="{index}">
            <a-button type="link" size="small" class="col-red" @click="handleDelete(index)">删除</a-button>
          </template>
        </a-table>
      </div>
  </a-modal>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import initSelect from '@/components/select.vue'
import { fetchSchoolList, examShare } from '@/api/index'
import { message } from 'ant-design-vue'
export default defineComponent({
  props: {
    value: {},
    title: {
      type: String,
      defualt: '提示'
    },
    list: {},
    columns: {}
  },
  components: {
    initSelect
  },
  setup (props, ctx) {
    const schoolList = ref([])
    const sid = ref(null)
    // 获取学校列表
    const fetchShoolLists = () => {
      fetchSchoolList().then(res => {
        schoolList.value = res.data
      })
    }
    // 删除列
    const handleDelete = (index) => {
      ctx.emit('delete', index)
    }
    const handleCancel = () => {
      ctx.emit('update:value', false)
    }
    const saveSubmit = () => {
      if (sid.value === null) {
        message.error('请选择学校')
        return
      }
      ctx.emit('update:value', false)
      ctx.emit('saveSubmit', sid.value)
    }
    onMounted(() => {
      fetchShoolLists()
    })
    return {
      schoolList,
      sid,
      handleDelete,
      // columns,
      handleCancel,
      saveSubmit
    }
  }
})
</script>

<style lang="scss" scoped>
.flex{
  display: flex;
  align-items: center;
  .myselect{
    flex: 1;
  }
}
.head{
  padding: 20px 0;
  font-weight: bold;
}
.table-box{
  height: 460px;
}
</style>
