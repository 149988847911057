
import { defineComponent, onMounted, reactive, toRefs, ref, computed } from 'vue'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import CreateExam from './createExam.vue'
import { useRouter } from 'vue-router'
import { getExamList, deleteExamList, fetchSchoolList, examShare, examTag } from '@/api/index'
import { screenObj, showConfirm, setPage } from '@/hooks/common-hooks'
import { message } from 'ant-design-vue'
import { DownOutlined } from '@ant-design/icons-vue'
import initSelect from '@/components/select.vue'
import { EXERISE_TAG } from '@/hooks/config'
import { getInfo } from '@/api/cookie'
import checkListModal from './components/checkList.vue'
const btnList: BtnProps[] = [
  {
    id: 1,
    btnName: '试卷列表'
  },
  {
    id: 2,
    btnName: '创建试卷'
  }
]
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: '试卷名',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '选择题',
    dataIndex: 'choice_count',
    key: 'choice_count'
  },
  {
    title: '判断题',
    dataIndex: 'true_count',
    key: 'true_count',
    slots: { customRender: 'state' }
  },
  {
    title: '连线题',
    dataIndex: 'line_count',
    key: 'line_count'
  },
  {
    title: '考试时间',
    dataIndex: 'exam_time',
    key: 'exam_time'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
const sharecolumns = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  align: 'center',
  width: 60
}, {
  title: '试卷名',
  dataIndex: 'name',
  key: 'name'
}, {
  title: '',
  key: 'action',
  slots: { customRender: 'action1' },
  align: 'right'
}]
interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}
const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  name: 'ExamManager',
  components: {
    mangerPageHeader,
    CreateExam,
    DownOutlined,
    initSelect,
    checkListModal
  },
  setup () {
    const exeriseList = ref([])
    // const examTagList = ref([])
    const getExamTag = () => {
      examTag({}).then(res => {
        exeriseList.value = res.data
      })
    }
    const schoolList = ref([])
    const router = useRouter()
    const tableData = reactive({
      columns,
      data: [{
        id: 1,
        name: '大扫除'
      }],
      pagination: { ...pagination },
      sid: null,
      tag: null,
      search: ''
    })
    // 获取学校列表
    const fetchShoolLists = () => {
      fetchSchoolList().then(res => {
        schoolList.value = res.data
      })
    }
    // 初始化页面
    const fetchList = (isDelete?: boolean) => {
      getExamList(screenObj({
        sid: tableData.sid,
        tag: tableData.tag,
        search: tableData.search.trim(),
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      })).then(res => {
        tableData.data = res.data
        tableData.pagination.total = res.result
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          fetchList()
        }
      })
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(fetchList)
    // 翻页
    const changePage = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      fetchList()
    }
    // 删除试卷
    const handleDelete = (record: any) => {
      showConfirm({
        content: '此操作不可逆，确定删除该试卷吗?',
        callBack: () => {
          deleteExamList({
            id: record.id
          }).then(() => {
            message.success('删除成功')
            fetchList(true)
          })
        }
      })
    }
    // 去编辑基础信息
    const goEdit = (id: number) => {
      router.push({
        name: 'createExam',
        params: { id }
      })
    }
    // 去编辑试卷详情
    const goEdit2 = (id: number) => {
      router.push({
        name: 'editExam',
        params: { id }
      })
    }
    // 搜索
    const fetchSearch = () => {
      tableData.pagination.current = 1
      fetchList()
    }
    const user: any = getInfo()
    const isSearch = JSON.parse(user)?.school_id === 1
    // 记录已选列表
    const checkList = ref([])
    const rowSelection = reactive({
      selectedRowKeys: [],
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        rowSelection.selectedRowKeys = selectedRowKeys
        console.log(rowSelection.selectedRowKeys)
        if (checkList.value.length === 0) {
          checkList.value = selectedRows
        } else {
          checkList.value = checkList.value.concat(selectedRows.filter((item: any) => !checkList.value.find((items: any) => items.id === item.id)))
        }
        checkList.value = checkList.value.filter((item: any) => selectedRowKeys.indexOf(item.id) !== -1)
        console.log(checkList.value)
      }
    })
    const hasSelected = computed(() => rowSelection.selectedRowKeys.length)
    const visibleEnjoy = ref(false)
    const handleEnjoy = () => {
      if (hasSelected.value > 0) {
        visibleEnjoy.value = true
      } else {
        message.error('请至少选择一项才可分享')
      }
    }
    const deleteCheckList = (index: number) => {
      const _list: any[] = checkList.value.splice(index, 1)
      const _index = rowSelection.selectedRowKeys.findIndex((item: any) => item === _list[0].id)
      rowSelection.selectedRowKeys.splice(_index, 1)
    }
    const getIds = (data: any) => {
      return data.map((item: any) => item.id)
    }
    const examShareHttp = (sid: any, list: any) => {
      if (sid.value === null) {
        message.error('请先选择学校')
        return false
      }
      examShare({
        school_id: parseInt(sid),
        exam_ids: getIds(list)
      }).then(res => {
        message.success('分享成功')
      })
    }
    const saveSubmit = (sid: any) => {
      // 学校[school_id]
      console.log(sid, '选择的学校id')
      console.log(checkList.value, '选中的试卷列表')
      examShareHttp(sid, checkList.value)
    }
    onMounted(() => {
      getExamTag()
      fetchShoolLists()
      fetchList()
    })
    return {
      sharecolumns,
      changeIndex,
      btnIndex,
      btnList,
      ...toRefs(tableData),
      changePage,
      goEdit,
      goEdit2,
      handleDelete,
      schoolList,
      exeriseList,
      fetchList,
      fetchSearch,
      isSearch,
      rowSelection,
      hasSelected,
      visibleEnjoy,
      handleEnjoy,
      checkList,
      deleteCheckList,
      saveSubmit
    }
  }
})
